import React, { Fragment, useEffect } from "react";
import Header from "src/components/layout/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { toggleTheme } from "src/redux/themeSlice";
import { Container as RBContainer } from "react-bootstrap";
import ContainerRoutes from "src/routes/ContainerRoutes";
import { SettingsProvider } from "src/shared/context/SettingsContext";
import { useQuery } from "react-apollo";
import { removeCookies } from "src/shared/SessionHelper";
import { QUERY_COMPANY_FEATURE_FLAGS } from "src/graphql/campaigns/postcard/queries";
import { setFeatureFlags } from "src/redux/featureFlagsSlice";
import { sortFeatureFlags } from "../../shared/FeatureFlagsHelper";
import "./Container.scss";

const Container = ({ client }) => {
  // variables
  const dispatch = useDispatch();
  const location = useLocation();
  const { darkMode } = useSelector((state) => state.theme);
  const { isAuthenticated, userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      toggleTheme(
        location?.pathname.includes("prospect") &&
          !location?.pathname.includes("dashboard/prospectmarketing"),
      ),
    );
  }, [location.pathname]);

  useEffect(() => {
    if (darkMode) {
      document.body.className = "dark-mode";
    } else {
      document.body.removeAttribute("class");
    }
  }, [darkMode]);

  useQuery(QUERY_COMPANY_FEATURE_FLAGS, {
    variables: {
      companyId: userInfo?.companyId,
    },
    onCompleted: (data) => {
      if (data.companyFeatureFlags) {
        const result = sortFeatureFlags(data.companyFeatureFlags);
        dispatch(setFeatureFlags(result));
      }
    },
  });

  const history = useHistory();
  if (!isAuthenticated) {
    removeCookies();
    sessionStorage.clear();
    history.push(`/company/login`);
    return null;
  }

  return (
    <RBContainer fluid className={`px-0 mx-0 main-container`}>
      {/* Setting context provider  */}
      <SettingsProvider client={client}>
        <Fragment>
          <Header client={client}></Header>
          <ContainerRoutes client={client} />
        </Fragment>
      </SettingsProvider>
    </RBContainer>
  );
};

export default Container;
